
import { getReconcileList, insertReconcile } from '@/api/reconcile/reconcile.api'
import { defineComponent, onMounted, reactive } from 'vue'
import { formatDefault, convertToDate } from '@/utils/dayjs'
import ReconcileInsertDialog from '@/components/reconcile/ReconcileInsertDialog.vue'
import { ElNotification } from 'element-plus'
import { useCourierStore } from '@/pinia/courier/courier.pinia'

export default defineComponent({
	name: 'ReconcileView',
	components: {
		ReconcileInsertDialog,
	},
	setup () {
		const state = reactive({
			isFetching: false,
			allHoldingAmount: 0,
			reconcileList: [] as any,
			pagination: {
				offset: 1,
				limit: 10,
				totals: 1,
				pages: 0
			},
			isShowDialogInsert: false,
			courierList: [],
			courier: null,
			isSubmittingReconcile: false,
		})

		const fetchReconcileList = () => {
			const courierStore = useCourierStore()
			state.isFetching = true
			getReconcileList({ limit: state.pagination.limit, offset: state.pagination.offset })
				.then(({ data: { data, pagination, allHoldingAmount } }: any) => {
					state.reconcileList = courierStore.mapCouriersWithOrder({ data }).data
					state.allHoldingAmount = allHoldingAmount
					state.pagination = {
						...state.pagination,
						totals: pagination.totals,
						pages: pagination.pages
					}
				})
				.finally(() => {
					state.isFetching = false
				})
		}
		const submitReconcile = async (reconciles: any) => {
			//
			state.isSubmittingReconcile = true
			try {
				const promiseAllReconcileUpload = reconciles.map((each: any) => {
					const payload = {
						courierId: each.courierId,
						reconciles: each.reconciles,
						startDate: each.startDate,
						endDate: each.endDate
					}
					return insertReconcile(payload)
				})

				await Promise.all(promiseAllReconcileUpload)
				
				ElNotification({
					title: 'สำเร็จ',
					message: 'อัพโหลดสำเร็จ',
					type: 'success'
				})

				fetchReconcileList() // refetch
			} catch (error) {
				console.error(error)
			} finally {
				state.isSubmittingReconcile = false
			}
		}
		onMounted(() => {
			fetchReconcileList()
		})
		return {
			formatDefault,
			state,
			fetchReconcileList,
			submitReconcile,
			convertToDate,
		}
	}
})
